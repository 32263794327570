<template>
	<ValidationObserver v-slot="{ valid, invalid, validated, passes, validate, handleSubmit, errors }" ref="observer">

	<div class="fill-height pa-5 pa-sm-10" v-if="AppStore.started_app" id="appreciation-page">
		<v-container class="fill-height d-flex align-center flex-row">
			<v-row class="">
				<v-card elevation="0" class="mx-auto  pa-5 pa-sm-10 mb-5" rounded="xl">
                    <v-col cols="12" class="text-h2 pt-5 pb-7 text-center my-auto" id="appreciation-title"
                           v-if="AppStore.data.configuration.display_appreciation_title">
                        <span v-html="AppStore.data.configuration[AppStore.locale].appreciation_title"></span>
                    </v-col>

					<v-col cols="12" id="appreciation-questions" v-if="questions_to_display && questions_to_display.length">
						<div v-for="(question, questionIndex) in questions_to_display"
						     :key="questionIndex+questionKey" class="appreciation-question">
							<divider-view v-if="question.format === 'divider'"></divider-view>
							<slider-view :class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
							             v-if="question.format === 'slider'"
							             :rules="question.is_required ? { regex: /^[1-5]+$/ } : ''"
							             v-model="questions_to_display[questionIndex]"
							             :questionIndex="questionIndex" :lang="AppStore.locale"></slider-view>
							<text-view :class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
							           v-if="question.format === 'text'"
							           :rules="question.is_required ? 'required' : ''"
							           v-model="questions_to_display[questionIndex]"
							           :questionIndex="questionIndex" :lang="AppStore.locale"></text-view>
							<textarea-view :class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
							               v-if="question.format === 'textarea'"
							               :rules="question.is_required ? 'required' : ''"
							               v-model="questions_to_display[questionIndex]"
							               :questionIndex="questionIndex" :lang="AppStore.locale"></textarea-view>
							<select-view :class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
							             v-if="question.format === 'select'"
							             :rules="question.is_required ? 'required' : ''"
							             return-object
							             :name="questions_to_display[questionIndex].name"
							             v-model="questions_to_display[questionIndex]"
							             :questionIndex="questionIndex" :lang="AppStore.locale"></select-view>
							<checkbox-view :class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
							               v-if="question.format === 'checkbox'"
							               :rules="question.is_required ? 'required' : ''"
							               :color="AppStore.data.general.primary_color"
							               v-model="questions_to_display[questionIndex]"
							               :questionIndex="questionIndex" :lang="AppStore.locale"></checkbox-view>
							<radio-view :class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
							            v-if="question.format === 'radio'"
							            :rules="question.is_required ? 'required' : ''"
							            :color="AppStore.data.general.primary_color"
							            v-model="questions_to_display[questionIndex]"
							            :questionIndex="questionIndex" :lang="AppStore.locale"></radio-view>
							<switch-view :class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
							             v-if="question.format === 'switch'"
							             :rules="question.is_required ? 'required' : ''"
							             :color="AppStore.data.general.primary_color"
							             v-model="questions_to_display[questionIndex]"
							             :questionIndex="questionIndex" :lang="AppStore.locale"></switch-view>
						</div>
					</v-col>

                    <v-col cols="12" class="text-center">
<!--                        <v-btn v-if="_.isEmpty(AppStore.rating.questions_answers)"-->
<!--                               @click="passes(submit); AppStore.rating.questions_answers = {}"-->
<!--                               @keypress.enter="navigate" role="link" rounded-->
<!--                               outlined elevation="0"-->
<!--                               :large="AppStore.data.general.button_size === 'large'"-->
<!--                               :x-large="AppStore.data.general.button_size === 'x-large'"-->
<!--                               :x-small="AppStore.data.general.button_size === 'x-small'"-->
<!--                               :small="AppStore.data.general.button_size === 'small'"-->
<!--                               :color="Helpers.getColor(AppStore.data.general.primary_color.hexa)" class="revert-btn text-button"-->
<!--                               :style="Helpers.getButtonSize(AppStore.data.general.button_size)"-->
<!--                               v-html="AppStore.data.configuration[AppStore.locale].appreciation_retry_btn_text">-->
<!--                        </v-btn>-->
                        <v-btn @click="passes(submit)" role="link" rounded
                               elevation="0" dark
                               :large="AppStore.data.general.button_size === 'large'"
                               :x-large="AppStore.data.general.button_size === 'x-large'"
                               :x-small="AppStore.data.general.button_size === 'x-small'"
                               :small="AppStore.data.general.button_size === 'small'"
                               @keypress.enter="navigate"
                               :color="Helpers.getColor(AppStore.data.general.primary_color.hexa)"
                               class="text-button"
                               :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
                               v-html="AppStore.data.configuration[AppStore.locale].appreciation_validate_btn_text">
                        </v-btn>
                    </v-col>
				</v-card>
			</v-row>
		</v-container>
	</div>
	</ValidationObserver>
</template>

<script>
import VTextAreaWithValidation from "../../../components/communs/inputs/VTextAreaWithValidation.vue";
import AppStore from "../../../stores/AppStore";
import _ from 'lodash';
import SliderView from '../../../components/communs/inputViewer3/SliderView.vue'
import TextView from '../../../components/communs/inputViewer3/TextView.vue'
import TextareaView from '../../../components/communs/inputViewer3/TextareaView.vue'
import SelectView from '../../../components/communs/inputViewer3/SelectView.vue'
import CheckboxView from '../../../components/communs/inputViewer3/CheckboxView.vue'
import RadioView from '../../../components/communs/inputViewer3/RadioView.vue'
import SwitchView from '../../../components/communs/inputViewer3/SwitchView.vue'
import DividerView from "../../../components/communs/inputViewer3/DividerView.vue";
import Helpers from "../../../services/Helpers";
import {ValidationObserver} from 'vee-validate';
import {EventBus} from "../../../services/Request";

export default {
	name: 'appreciation-view',

	components: {
		ValidationObserver,
        DividerView,
		VTextAreaWithValidation,
		SliderView,
		TextView,
		TextareaView,
		SelectView,
		CheckboxView,
		RadioView,
		SwitchView
	},

	props: {},

	data: () => ({
		AppStore,
		outlinedTextArea: false,
		questions: [],
		questions_to_display: [],
		questionKey:0
	}),

	created() {
	},

	async mounted() {
		EventBus.$on('refreshQuestions', (data) => {
			this.questionKey = this.questionKey+1
		});
		this.questions = []
		this.questions_to_display = []
		await this.getBookmarkedQuestions()
		if (AppStore.questions_by_page[2] && AppStore.questions_by_page[2].length > AppStore.data.configuration.questions_to_display_2) {
			await this.getRandomQuestions();
		} else {
			this.questions = Object.assign([], _.filter(AppStore.questions_by_page[2], (q) => {
				return !q.is_bookmarked
			}))
			this.questions_to_display = this.questions_to_display.concat(this.questions)
		}
	},

	computed: {
        Helpers() {
            return Helpers
        },
		_() {
			return _;
		}
	},

	watch: {
		
	},

	methods: {
		getBookmarkedQuestions() {
			this.questions_to_display = Object.assign([], AppStore.bookmarkedQuestions[2])
		},

		getRandomQuestions() {
			const max = AppStore.data.configuration.questions_to_display_2 - this.questions_to_display.length
			this.questions = Object.assign([], _.filter(AppStore.questions_by_page[2], (q) => {
				return !q.is_bookmarked
			}))

			for (let i = 0; i < max; i++) {
				const randomQuestionIndex = Math.floor(Math.random() * (this.questions.length));
				this.questions_to_display.push(this.questions[randomQuestionIndex]);
				this.questions.splice(randomQuestionIndex, 1)
			}
		},
		submit() {
			this.$router.push({name: 'tb-3-comment'})
		},
	}
};
</script>
<style>

</style>
